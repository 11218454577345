import React from "react"
import { Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CountDownTimer from "../components/countdowner"

const IndexPage = () => (
  <Layout customWidth="">
    <SEO title="Welcome" />

    <div>
    <h2 style={{backgroundColor:"#ff686b",display:"inline",}}>/members</h2>
    
    <p style={{marginTop:"0.5em",}}>
      Welcome to the members area.<br></br>
      You have access to this because we believe in you, <u>don't forget that.</u></p>

      <ul>
        <li><Link style={{marginBottom:"0.5em"}} to="/docs">All Documents</Link></li>
      </ul>
      <br></br>
      <h2 style={{marginBottom:"0.3em",}}>
        Shopify snipets & more</h2>
        <p>- <Link style={{marginBottom:"0.5em"}} to="/docs/shopify/cookie-banner">Cookie banner</Link>
        <br></br>
        <i>
          Become "almost" compliant with cookies and stop missing out on sales!
        </i></p>
        <br></br>


        




    </div>
  </Layout>
)

export default IndexPage
